import React from 'react'
import "./Privacystyle.css";

function privacy() {
  return (
    <>

   <div className="Privacy_policies">
   <p
          style={{
            margin: '0in',
            marginBottom: '6.0pt',
            fontSize: 15,
            fontFamily: '"Calibri",sans-serif',
            marginTop: '20.0pt',
            marginRight: '0in',
            marginLeft: '0in',
            lineHeight: '115%',
          }}
        >
          <h1 className='heading'
            style={{
              fontSize: 27,
              lineHeight: '115%',
              fontFamily: '"Arial",sans-serif',
            }}
          >
            Our Privacy Policy
          </h1>
        </p>
        <p id="update">Last updated on 16 January 2023.</p>
          <div class="solid"></div>
  
  <p>Your privacy is top priority for Everlove and we respect your right to learn the process on how the information about you is used. For this reason, we are committed to maintain your privacy and keep your information safe. This Privacy Policy describes Everlove’s policies and procedures on the collection, use and disclosure of your information when you use our website and application. We use your personal data to provide and improve our service on the Everlove website and application. <strong>By using Everlove Services, you give your consent to the practices described in this Privacy Policy.</strong></p>
  
  <p>Definitions We Use</p>
  
  <p>For the purposes of this Privacy Policy:</p>
  
  <ul>
    <li><strong><em>Account</em></strong> means a unique account created for you to access our Everlove Services or parts of our Services.</li>
  </ul>
  
  <ul>
    <li><strong><em>Application </em></strong>means the software program named Everlove, which provided by the Company downloaded by you through an Application Store's account to a device.</li>
  </ul>
  
  <ul>
    <li><strong><em>Application Store</em></strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) by which the application has been downloaded to your device.</li>
  </ul>
  
  <ul>
    <li><strong><em>Business</em></strong>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects consumers' personal information and determines the purposes and means of the processing of consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.</li>
  </ul>
  
  <ul>
    <li><strong><em>Company</em></strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to <strong>EVER INDUSTRIES LTD </strong>Company<em> and </em>for the purpose of the GDPR, the Company is the Data Controller.</li>
  </ul>
  
  <ul>
    <li><strong><em>Country</em></strong> refers to (United Kingdom) which is the place of headquarters of our company.</li>
  </ul>
  
  <ul>
    <li><strong><em>Consumer</em></strong>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.</li>
  </ul>
  
  <ul>
    <li><strong><em>Cookies</em></strong> are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website among its many uses.</li>
  </ul>
  
  <ul>
    <li><strong><em>Data Controller</em></strong>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</li>
  </ul>
  
  <ul>
    <li><strong><em>Device</em></strong> means any device that can access the Service such as a computer, a cell phone or a digital tablet.</li>
  </ul>
  
  <ul>
    <li><strong><em>Do Not Track (DNT)</em></strong> is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</li>
  </ul>
  
  <ul>
    <li><strong><em>Personal Data</em></strong> is any information that relates to an identified or identifiable individual.<br /> <br /> For the purposes of GDPR, Personal Data means any information relating to you such as a name, an identification number, location data, and online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</li>
  </ul>
  
  <p>For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with you.</p>
  
  <ul>
    <li><strong><em>Service</em></strong> refers to the Everlove Website and Application.</li>
  </ul>
  
  <ul>
    <li><strong><em>Service Provider</em></strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.</li>
  </ul>
  
  <ul>
    <li><strong><em>Usage Data</em></strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
  </ul>
  
  <ul>
    <li><strong><em>Website</em></strong> refers to Everlove accessible from (everlove.uk)</li>
  </ul>
  
  <ul>
    <li><strong><em>You</em></strong> means the individual accessing or using the Everlove Services, or the company, or other legal entity on behalf of which such individual is accessing or using the service, as applicable. Under GDPR (General Data Protection Regulation), you can be referred to as the Data Subject or as the User as you are the individual using the service.</li>
  </ul>
  
  <p>What Type of Personal Data Does Everlove Collect?</p>
  <p><em>Personal Data</em></p>
  
  <p>While using Everlove Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
  
  <ul>
    <li>First name and last name</li>
    <li>Phone number</li>
    <li>Address, State, Province, ZIP/Postal code, City</li>
    <li>Usage Data</li>
    <li>Credit or Debit Card Information</li>
    <li>Email address</li>
  </ul>
  <p><em>Usage Data</em></p>
  
  <p>Usage Data is collected automatically when using the Everlove Services.</p>
  
  <p>Usage Data may include information such as your device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
  
  <p>When you access the Everlove Services by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser you use, unique device identifiers and other diagnostic data.</p>
  
  <p>We may also collect information that your browser sends whenever you visit our service or when you access the service by or through a mobile device.</p>
  
  <p><em>Tracking Technologies and Cookies</em></p>
  
  <p>We use Cookies and similar tracking technologies to track the activity on Everlove Services and store certain information.</p>
  
  <p>We use <em>Session Cookies</em> named as Necessary / Essential Cookies to provide you with services available through the Everlove Services and to enable you to use some of its features. These Cookies help us to authenticate users. Without these Cookies, the services that you have asked for cannot be provided, and we only use these Cookies to provide you with those services.</p>
  
  <p>We also use <em>Persistent Cookies</em> named as Notice Acceptance Cookies to identify if users have accepted the use of cookies on the Everlove Services, Functionality Cookies to remember choices you make when you use the Everlove Services, such as remembering your login details or language preference, and finally Tracking and Performance Cookies to track information about traffic to the Everlove Services.</p>
  
  <p><em>You’re Choices Regarding Cookies</em></p>
  
  <p>If you prefer to avoid the use of Cookies on the website, first you must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>
  
  <p>If you do not accept our Cookies, you may experience some inconvenience in your use of the website and some features may not function properly.</p>
  
  <p>If you'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.</p>
  
  <ul>
    <li>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</a></li>
  </ul>
  
  <ul>
    <li>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a></li>
  </ul>
  
  <ul>
    <li>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></li>
  </ul>
  
  <ul>
    <li>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
  </ul>
  
  <p>For any other web browser, please visit your web browser's official web pages.</p>
  
  <p>How Does Everlove Use Your Personal Data?</p>
  
  <p>We may use your personal data for the following purposes:</p>
  
  <ul>
    <li><strong><em>To administer your account, provide, improve and maintain our service.</em></strong> We use your personal data to monitor the usage of our service, to create and manage your account, to provide you with customer support and respond to your requests, to complete your transactions, to communicate with you about our services and to provide you the access to different functionalities of the service that are available to you as a registered user.</li>
  </ul>
  
  <ul>
    <li><strong><em>To help you connect with other users. </em></strong>We use your personal data to build a connection between users including recommending you other members to meet and showing members’ profiles to one another.</li>
  </ul>
  
  <ul>
    <li><strong><em>To manage the advertising and marketing campaigns.</em></strong> We use your personal data for advertising reasons such as conducting sweeptakes, contests, discounts or other offers.</li>
  </ul>
  
  <ul>
    <li><strong><em>To comply with legal obligations</em></strong><strong>.</strong>In certain cases, we collect and use your personal information to comply with laws.</li>
  </ul>
  
  <ul>
    <li><strong><em>To prevent, detect and fight fraud and other illegal or unauthorized activities.</em></strong> For more information, please read our <em>Terms of Use</em> for Everlove Services.</li>
  </ul>
  
  <ul>
    <li><strong><em>For other purposes.</em></strong> We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our products, services, marketing and your experience.</li>
  </ul>
  
  
  <p>We may share your personal information in the following situations:</p>
  
  <ul>
    <li><strong><em>With service providers.</em></strong> We may share your personal information with third-party service providers to monitor and analyze the use of our Service, for payment processing, to contact you.</li>
  </ul>
  
  <ul>
    <li><strong><em>With affiliates.</em></strong> We may share your information with our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</li>
  </ul>
  
  <ul>
    <li><strong><em>With business partners.</em></strong> We may share your information with our business partners to offer you certain products, services or promotions.</li>
  </ul>
  
  <ul>
    <li><strong><em>With other users.</em></strong> Your information will be disclosed when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
  </ul>
  
  <ul>
    <li><strong><em>With law enforcement when required by law.</em></strong> In certain cases, we share your personal information to comply with legal obligations, to assist in the prevention or detection of crime or to protect the safety of any person.</li>
  </ul>
  
  <ul>
    <li><strong><em>For business transfers</em></strong><strong>. </strong>We may share or transfer your personal information in connection with, any merger, sale, acquisition, divestiture, restricting, reorganization, dissolution, bankruptcy or other change of ownership control.</li>
    <li><strong><em>With your consent.</em></strong> We may disclose your personal information for any other purpose with your consent.</li>
  </ul>
  
  
  
  
  
  <p>Cross-Border Data Transfers</p>
  
  <p>Your information, including personal data, is processed at the <strong>EVER INDUSTRIES LTD </strong>Company operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
  
  <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
  
  <p>The <strong>EVER INDUSTRIES LTD </strong>Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
  
  <p>When we transfer personal information outside of the EEA, the United Kingdom, Switzerland or other countries which data protection laws have been deemed adequate by the European Commission or other competent governmental body, we use <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-sc">standard contract clauses</a> (standard contractual clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your data) or other appropriate transfer mechanism.</p>
  
  <p>How Does Everlove Retain Your Personal Data?</p>
  
  <p>We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
  
  <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
  
  <p>How Secure Is Information About Me?</p>
  
  <p>The security of your Personal Data is important to us but remember that you provide personal data at your own risk. Unfortunately, no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
  <p>In addition to this:</p>
  
  <ul>
    <li>We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of your personal information. For instance, we encrypt your personally identifiable information and, all requests from you to the server are established over a secure connection using HTTPS. This also means that Everlove Services is SSL encrypted. Our security procedures mean that we may ask to verify your identity before we disclose personal information to you.</li>
  </ul>
  
  <ul>
    <li>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</li>
  </ul>
  
  <ul>
    <li>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</li>
  </ul>
  
  <p>Our Policy in Terms of GDPR</p>
  <p><em>Legal Basis for Processing Personal Data under GDPR</em></p>
  
  <p>We may process your Personal Data under the following conditions:</p>
  
  <ul>
    <li><strong><em>With your consent</em></strong><strong>.</strong> You have given your consent for processing Personal Data for one or more specific purposes.</li>
  </ul>
  
  <ul>
    <li><strong><em>For performance of a contract</em></strong><strong>.</strong> Provision of Personal Data is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof.</li>
  </ul>
  
  <ul>
    <li><strong><em>For legal obligations</em></strong><strong>. </strong>Processing Personal Data is necessary for compliance with a legal obligation to which the <strong>EVER INDUSTRIES LTD </strong>Company is subject.</li>
  </ul>
  
  <ul>
    <li><strong><em>For vital interests.</em></strong> Processing Personal Data is necessary in order to protect your vital interests or of another natural person.</li>
  </ul>
  
  <ul>
    <li><strong><em>For public interests.</em></strong> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the <strong>EVER INDUSTRIES LTD </strong></li>
  </ul>
  
  <ul>
    <li><strong><em>For legitimate interests.</em></strong> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the <strong>EVER INDUSTRIES LTD </strong></li>
  </ul>
  
  <p>In any case, the <strong>EVER INDUSTRIES LTD </strong>Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
  
  <p><em>You’re Rights under the GDPR</em></p>
  
  <p>We as <strong>EVER INDUSTRIES LTD </strong>Company understand the importance of respecting the confidentiality of your Personal Data and guarantee you can exercise your rights.</p>
  
  <p>You have the right under this Privacy Policy and by law if you are within the EU, to:</p>
  
  <ul>
    <li><strong><em>Request access to your Personal Data.</em></strong> You have the right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you. This also enables you to receive a copy of the Personal Data we hold about you.</li>
  </ul>
  
  <ul>
    <li><strong><em>Request correction of the Personal Data that we hold about you.</em></strong> You have the right to have any incomplete or inaccurate information we hold about you corrected.</li>
  </ul>
  
  <ul>
    <li><strong><em>Object to processing of your Personal Data.</em></strong> This right exists where we are relying on a legitimate interest as the legal basis for our processing and there is something about your particular situation, which makes you want to object to our processing of your Personal Data on this ground. You also have the right to object where we are processing your Personal Data for direct marketing purposes.</li>
  </ul>
  
  <ul>
    <li><strong><em>Request erasure of your Personal Data.</em></strong> You have the right to ask us to delete or remove Personal Data when there is no good reason for us to continue processing it.</li>
  </ul>
  
  <ul>
    <li><strong><em>Request the transfer of your Personal Data. </em></strong>We will provide to you, or to a third-party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
  </ul>
  
  <ul>
    <li><strong><em>Withdraw your consent.</em></strong> You have the right to withdraw your consent on using your Personal Data. If you withdraw your consent, we may not be able to provide you with access to certain specific functionalities of the Everlove Services.</li>
  </ul>
  
  <p><em>How Can You Exercise Your GDPR Data Protection Rights?</em></p>
  
  <p>In order to exercise your rights of access, rectification, deletion, cancellation, and opposition you can contact us:</p>
  
  <ul>
    <li>By visiting the contact page on our website: (everlove.uk)</li>
    <li>By sending us an email: (everlove102@outlook.com)</li>
  </ul>
  
  <p>Please note that we may ask you to verify your identity before responding to such requests. If you make a request, we will try our best to respond to you as soon as possible.</p>
  
  <p>Our Policy in Terms of CCPA</p>
  
  <p>This privacy notice section for California residents supplements the information contained in our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.</p>
  
  <p><em>Categories of Personal Information Collected</em></p>
  
  <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device.</p>
  
  <p>The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months. Please note that the categories and examples provided in the list below are those defined in the CCPA.</p>
  
  <p><strong><em>Category A: Identifiers.</em></strong> Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers.</p>
  
  <p><strong><em>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)). </em></strong>Examples: A name, signature, address, telephone number, passport number, bank account number, credit card number, debit card number, or any other financial information or any other information listed in the statute.</p>
  
  <p><strong><em>Category D: Commercial information. </em></strong>Examples: Records and history of products or services purchased or considered.</p>
  
  <p><strong><em>Category F: Internet or other similar network activity</em></strong><strong>. </strong>Examples: Interaction with Everlove Services or advertisement.</p>
  
  <p><em>Use of Personal Information for Business Purposes or Commercial Purposes</em></p>
  
  <p>We may use or disclose personal information we collect for <em>business purposes</em> or <em>commercial purposes</em> (as defined under the CCPA), which may include the following examples:</p>
  
  <ul>
    <li>To operate our service and provide you with our service.</li>
    <li>To provide you with support and to respond to your inquiries, including investigating and addressing your concerns and monitoring and improving Everlove Services.</li>
    <li>To fulfil or meet the reason you provided the information. For example, if you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery.</li>
    <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
    <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
    <li>For internal administrative and auditing purposes.</li>
    <li>To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.</li>
  </ul>
  
  <p>If we decide to collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes we will update this Privacy Policy.</p>
  
  <p><em>Disclosure of Personal Information for Business Purposes or Commercial Purposes</em></p>
  
  <p>We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:</p>
  
  <ul>
    <li>Category A: Identifiers</li>
    <li>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
    <li>Category D: Commercial information</li>
    <li>Category F: Internet or other similar network activity</li>
  </ul>
  
  <p>Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been disclosed.</p>
  
  <p>When we disclose personal information for a business purpose or a commercial purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
  
  <p><em>Share of Personal Information</em></p>
  
  <p>We may share your personal information identified in the above categories with the following categories of third parties:</p>
  
  <ul>
    <li>Service Providers</li>
    <li>Payment processors</li>
    <li>Our affiliates</li>
    <li>Our business partners</li>
    <li>Third-party vendors to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you</li>
  </ul>
  
  <p><em>You’re Rights under the CCPA</em></p>
  
  <p>The CCPA provides California residents with specific rights regarding their personal information. If you are a resident of California, you have the following rights:</p>
  
  <ul>
    <li><strong><em>The right to notice.</em></strong> You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</li>
  </ul>
  
  <ul>
    <li><strong><em>The right to request.</em></strong> Under CCPA, you have the right to request that we disclose information to you about our collection, use, disclosure for business purposes and share of personal information.</li>
  </ul>
  
  <ul>
    <li><strong><em>The right to delete Personal Data.</em></strong> You have the right to request the deletion of your Personal Data, subject to certain exceptions. Once we receive and confirm your request, we will delete (and direct Our Service Providers to delete) your personal information from our records, unless an exception applies.</li>
  </ul>
  
  <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
  
  <ul>
    <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
    <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
    <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
    <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
    <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
    <li>Comply with a legal obligation.</li>
    <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
  </ul>
  
  <ul>
    <li><strong><em>The right not to be discriminated against.</em></strong> You have the right not to be discriminated against for exercising any of your consumer's rights, including by:</li>
  </ul>
  
  <ul>
    <li>Denying goods or services to you</li>
    <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</li>
    <li>Providing a different level or quality of goods or services to you</li>
    <li>Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services</li>
  </ul>
  <p><em>How Can You Exercise Your CCPA Data Protection Rights?</em></p>
  
  <p>In order to exercise any of your rights under the CCPA, and if you are a California resident, you can contact us:</p>
  
  <ul>
    <li>By visiting this page on our website: (everlove.uk)</li>
    <li>By sending us an email: (everlove102@outlook.com)</li>
  </ul>
  
  <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable request related to your personal information.</p>
  
  <p>Your request to us must:</p>
  
  <ul>
    <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative</li>
    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it</li>
  </ul>
  
  <p>We cannot respond to your request or provide you with the required information if we cannot:</p>
  
  <ul>
    <li>Verify your identity or authority to make the request</li>
    <li>And confirm that the personal information relates to you</li>
    <li>We will disclose and deliver the required information free of charge within 45 days of receiving your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.</li>
  </ul>
  <p><em>Do Not Track</em> Policy as Required by California Online Privacy Protection Act (CalOPPA)</p>
  
  <p>Our Everlove Services do not respond to Do Not Track signals.</p>
  
  <p>However, some third-party websites do keep track of your browsing activities. If you are visiting such websites, you can set your preferences in your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of your web browser.</p>
  
  <p>Children's Privacy</p>
  
  <p>Our Everlove Services does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from anyone under the age of 18 without verification of parental consent, we take steps to remove that information from our servers.</p>
  
  <p>If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.</p>
  
  <p>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</p>
  
  <p>California Business and Professions Code Section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>
  
  <p>To request removal of such data, and if you are a California resident, you can contact us using the contact information provided below, and include the email address associated with your account.</p>
  
  <p>Be aware that your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>
  
  <p>Links to Other Websites</p>
  
  <p>Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
  
  <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
  
  <p>Changes to this Privacy Policy</p>
  
  <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
  
  <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
  
  <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
  
  <p>Contact Us</p>
  
  <p>If you have any questions about this Privacy Policy, you can contact us:</p>
  
  <ul style={{ listStyleType: 'undefined', marginLeft: '0in' }}>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif' }}>
              By visiting this page on our website:{' '}
              <span style={{ color: '#4F81BD' }}><a href='https://everlove.uk/'>everlove.uk</a></span>
            </span>
          </li>
          <li>
            <span style={{ fontFamily: '"Arial",sans-serif' }}>
              By sending us an email:{' '}
              <span style={{ color: '#4F81BD' }}>
              <a href="mailto:everlove102@outlook.com">everlove102@outlook.com</a>
              </span>
            </span>
          </li>
        </ul>
</div>


    </>
  )
}

export default privacy
